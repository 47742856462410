import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import authApi from "../api/authApi";
import accountApi from "../api/accountApi";
import toast from "react-hot-toast";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  refreshLogin: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      console.log("initializing");
      try {
        const refresh = window.localStorage.getItem("refresh");

        if (refresh) {
          const user = await accountApi.getData();

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const { refresh, error } = await authApi
      .login({ username: email, password })
      .then(({ data }) => data)
      .catch(({ response }) => ({ error: response.data.detail }));

    if (error) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return { error };
    }

    localStorage.setItem("refresh", refresh);
    const user = await accountApi.getData();
    console.log("user", user);
    // TODO: remove if accountApi is ready

    console.log("Logging in", user);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
    return { success: true };
  };

  // login with refresh token
  const refreshLogin = async (refreshToken) => {
    const { refresh, error } = await authApi
      .refreshToken({ refresh: refreshToken })
      .then(({ data }) => data)
      .catch(({ response }) => ({ error: response.data.detail }));

    if (error) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return { error };
    }

    localStorage.setItem("refresh", refreshToken);
    const user = await accountApi.getData();

    console.log("Logging in", user, refresh);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
    return { success: true };
  };

  const logout = async () => {
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    dispatch({ type: "LOGOUT" });
    toast.success("You have logged out.");
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = {};

    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        refreshLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
