import { apiService, authService } from "../utils/axios";
import urls from "../constants/urls";

const login = (body) =>
  apiService.post(urls.AUTH_TOKEN_PAIR(), body).then((res) => ({ data: res.data, headers: res.headers }));

const refreshToken = (body) =>
  authService.post(urls.AUTH_TOKEN_REFRESH(), body).then((res) => ({ data: res.data, headers: res.headers }));

export default {
  login,
  refreshToken,
};
