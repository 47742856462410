import merge from "lodash/merge";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { THEMES } from "../constants";
import { lightShadows } from "./shadows";

const baseOptions = {
  direction: "ltr",
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
        colorDefault: {
          backgroundColor: "#ef4a10",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
          background: "#ef4a10 !important",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
        input: {
          padding: "8px 12px",
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          top: "-6px",
          "&.MuiInputLabel-shrink": {
            top: 0,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: 0,
          },
        },
        listbox: {
          fontSize: 14,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 4,
          minWidth: 400,
          maxWidth: 1400,
        },
        paperFullScreen: {
          width: "95%",
          height: "95%",
        },
        paperFullWidth: {
          maxWidth: 1400,
          height: "95%",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 24,
          paddingBottom: 8,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4F5F7",
          padding: "16px 24px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 4,
          lineHeight: "2em",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 600,
      fontSize: "3.5rem",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 600,
      fontSize: "3rem",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 600,
      fontSize: "2rem",
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontFamily:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 600,
      fontSize: "1.125rem",
      letterSpacing: "0.15px",
    },
    body: {
      fontSize: "0.9rem",
    },
    overline: {
      fontWeight: 600,
    },
    number: {
      fontFamily:
        '"Fira Code", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 400,
    },
    subtitle: {
      display: "block",
      fontWeight: 500,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    palette: {
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#F9FBFE",
        paper: "#ffffff",
        active: "rgba(231, 129, 10, 0.1)",
      },
      error: {
        contrastText: "#ffffff",
        main: "#EB5757",
      },
      errorKanban: {
        contrastText: "#ffffff",
        main: "#EB5757",
      },
      errorLight: {
        contrastText: "#222B45",
        main: "#FCDFD0",
      },
      infoLight: {
        contrastText: "#222B45",
        main: "#D9E0FE",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#ef4a10",
      },
      info: {
        contrastText: "#ffffff",
        main: "#0095FF",
      },
      secondary: {
        contrastText: "#f7f7f7",
        main: "rgba(66, 82, 110, .86)",
      },
      primaryLight: {
        contrastText: "#ef4a10",
        main: "rgba(231, 129, 10, 0.05)",
        border: "#E9F4FE",
      },
      secondaryLight: {
        contrastText: "#222B45",
        main: "#f7f7f7",
      },
      // not sure with contrastText
      secondaryDark: {
        contrastText: "rgba(233, 30, 99, 0.08)",
        main: "#BE134D",
      },
      grey: {
        contrastText: "#9E9E9E",
        main: "#FFFFFF",
        border: "#C7CED4",
      },
      greyDark: {
        contrastText: "#252858",
        main: "#EEEEEE",
        border: "#EEEEEE",
      },
      success: {
        contrastText: "#ffffff",
        main: "#00D68F",
      },
      successLight: {
        contrastText: "#222B45",
        main: "#baecac",
      },
      textLink: {
        main: "#0095FF",
        contrastText: "#0095FF",
      },
      successDark: {
        contrastText: "#00605C",
        main: "#EDF7ED",
      },
      text: {
        primary: "#222B45",
        info: "#ef4a10",
        secondary: "rgba(66, 82, 110, .86)",
        subtitle: "#7B8B9A",
        success: "#00C871",
        main: "#222B45",
        warning: "#FFAA00",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#FFAA00",
      },
      warningLight: {
        contrastText: "#222B45",
        main: "#FFE9B4",
      },
      statusAwaiting: {
        contrastText: "#CC9000",
        main: "rgba(231, 223, 31, 0.3)",
      },
      statusPendingRequest: {
        contrastText: "#056CAE",
        main: "rgba(187, 221, 225, 0.3)",
      },
      statusCreated: {
        contrastText: "#B76600",
        main: "rgba(244, 221, 160, 0.3)",
      },
      statusReceived: {
        contrastText: "#2D6006",
        main: "rgba(135, 173, 72, 0.3)",
      },
      statusSentToLabs: {
        contrastText: "#2D6006",
        main: "rgba(135, 173, 72, 0.3)",
      },
      statusShipped: {
        contrastText: "#00605C",
        main: "rgba(123, 221, 133, 0.3)",
      },
      statusDelivered: {
        contrastText: "#056CAE",
        main: "rgba(187, 225, 221, 0.3)",
      },
      statusArchived: {
        contrastText: "#212121",
        main: "rgba(192, 192, 192, 0.3)",
      },
      statusActive: {
        contrastText: "#00605C",
        main: "rgba(215, 226, 192, 0.3)",
      },
      statusApproved: {
        contrastText: "rgba(0, 0, 0, 0.6)",
        main: "#A1DCA3",
      },
      statusRejected: {
        contrastText: "#BE134D",
        main: "rgba(233, 30, 99, 0.08)",
      },
    },
    shadows: lightShadows,
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 4,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
