import { Fragment, useEffect, useState, useMemo } from "react";
import { Outlet, useLocation, Link as RouterLink } from "react-router-dom";
import { experimentalStyled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BookOpen from "../../icons/BookOpen";
import Cash from "../../icons/CashOutlined";
import Inbox from "../../icons/Inbox";
import AccessTime from "@mui/icons-material/AccessTime";
import Beaker from "../../icons/Beaker";
import Scrollbar from "../Scrollbar";
import NavSection from "../NavSection";
import { Avatar, Button, Menu, MenuItem, MenuList, Tooltip, Typography } from "@mui/material";
import DownIcon from "../../icons/ArrowHeadDown";
import UsersIcon from "../../icons/Users";
import LogoutIcon from "../../icons/LogOut";
import IdentificationIcon from "../../icons/Identification";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants";
// eslint-disable-next-line no-unused-vars
import { useGetSalePriceRequestCountQuery } from "../../services/quoteApi";
import Medicine from "../../icons/Medicine";
import Kanban from "../../icons/Kanban";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = experimentalStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "none",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = experimentalStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ENABLE_APPROVAL = false;

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const location = useLocation();
  const { user, logout } = useAuth();
  const [requestCount, setRequestCount] = useState(0);

  if (user.role === 1 && ENABLE_APPROVAL) {
    const { data } = useGetSalePriceRequestCountQuery(null, { pollingInterval: 60000, refetchOnFocus: true });
    useEffect(() => {
      setRequestCount(data || 0);
    }, [data]);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const sections = useMemo(
    () => [
      {
        title: "General",
        items: [
          {
            title: "Quotes",
            path: "/quotes",
            roles: [1, 2, 3, 4],
            icon: <BookOpen />,
          },
          {
            title: "Ingredients",
            path: "/ingredients",
            icon: <Beaker />,
          },
          {
            title: "Daily Values",
            path: "/daily-values",
            icon: <AccessTime />,
          },
          {
            title: "Price Requests",
            path: "/price-requests",
            icon: <Inbox />,
            roles: [1, 4],
            count: requestCount,
          },
          {
            title: "Pricing Model",
            path: "/pricing-model",
            icon: <Cash />,
            roles: [1, 2, 3, 4],
          },
          {
            title: "Plastics Cost",
            path: "/plastics",
            icon: <Medicine />,
          },
          {
            title: "Sample Lab",
            path: "/sample-lab",
            icon: <Kanban fontSize="small" />,
          },
        ],
      },
    ],
    [requestCount],
  );

  const userName = `${user.first_name} ${user.last_name}`;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "background.paper" }}>
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <RouterLink to="/" style={{ display: "contents" }}>
              <img src="/logo-new.png" alt="logo" height="28" />
            </RouterLink>
          </Box>
          <img src="/houser-logo.png" alt="logo-houser" height="28" />
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <Button onClick={handleOpenUserMenu} sx={{ p: 0, justifyContent: "space-between" }}>
                <Avatar alt={userName} sx={{ backgroundColor: "#131146" }}>
                  {userName.substr(0, 1)}
                </Avatar>
                <Typography color="text.primary" fontWeight="bold" fontSize={14} sx={{ mx: 2 }}>
                  {userName}
                </Typography>
                <DownIcon fontSize="small" sx={{ mt: 2 }} />
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: 6 }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuList sx={{ width: 270, maxWidth: "100%", p: 0 }}>
                <MenuItem onClick={handleCloseUserMenu} sx={{ display: "block" }}>
                  <Typography variant="body2" fontWeight="bold">
                    {ROLES[user.role]}
                  </Typography>
                  <Typography variant="body2">{user.email}</Typography>
                </MenuItem>
                <Divider />
                {user.role === 1 && (
                  <MenuItem to="/account/users" sx={{ py: 1 }} component={RouterLink} onClick={handleCloseUserMenu}>
                    <UsersIcon fontSize="small" sx={{ mr: 2 }} color="secondary" />
                    <Typography variant="body">Manage Users</Typography>
                  </MenuItem>
                )}
                <MenuItem to="/contacts" sx={{ py: 1 }} component={RouterLink} onClick={handleCloseUserMenu}>
                  <IdentificationIcon fontSize="small" sx={{ mr: 2 }} color="secondary" />
                  <Typography variant="body">Contacts</Typography>
                </MenuItem>
                <MenuItem onClick={logout} sx={{ py: 1 }}>
                  <LogoutIcon fontSize="small" sx={{ mr: 2 }} color="error" />
                  <Typography variant="body2" color="error" fontWeight="bold">
                    Log Out
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Scrollbar options={{ suppressScrollX: true }}>
          <Box sx={{ p: 2 }}>
            {sections.map((section) => (
              <Fragment key={section.title}>
                <NavSection
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                  drawerOpen={open}
                />
              </Fragment>
            ))}
          </Box>
        </Scrollbar>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
