import { ToastBar, Toaster } from "react-hot-toast";

import InfoIcon from "@mui/icons-material/InfoOutlined";
import ErrorIcon from "../icons/ExclamationCircle";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { capitalize } from "lodash";

const AppToaster = () => (
  <Toaster
    position="bottom-center"
    containerStyle={{
      bottom: 50,
    }}
    toastOptions={{
      duration: 4000,
      style: {
        borderRadius: 4,
        maxWidth: 1000,
        fontSize: 14,
        background: "#3366FF",
        border: "none",
        color: "#FFFFFF",
      },
      iconTheme: {
        primary: "#00D68F",
        secondary: "#FFFFFF",
      },
      success: {
        style: {
          background: "#00D68F",
        },
        iconTheme: {
          primary: "#00D68F",
          secondary: "#FFFFFF",
        },
      },
      error: {
        icon: <ErrorIcon fontSize="small" />,
        style: {
          background: "#FF3D71",
        },
        iconTheme: {
          primary: "#FF3D71",
          secondary: "#FFFFFF",
        },
      },
      blank: {
        icon: <InfoIcon fontSize="small" />,
      },
    }}
  >
    {(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <Box display="flex" alignItems="center" sx={{ minWidth: 250 }} p={0.5}>
            {icon}
            <Box ml={2}>
              {t.type !== "blank" && (
                <Typography fontWeight={700} fontSize={14}>
                  {capitalize(t.type)}
                </Typography>
              )}
              <Box m={0} sx={{ div: { m: 0, fontSize: 12 } }}>
                {message}
              </Box>
            </Box>
          </Box>
        )}
      </ToastBar>
    )}
  </Toaster>
);

export default AppToaster;
