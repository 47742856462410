/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";
import useAuth from "../hooks/useAuth";

const renderNavItems = ({ depth = 0, items, pathname, drawerOpen, user }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
          drawerOpen,
          user,
        }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth, drawerOpen, user }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname,
      )
    : false;

  if (!item.roles || item.roles.includes(user.role)) {
    if (item.children) {
      const partialMatch = item.path
        ? !!matchPath(
            {
              path: item.path,
              end: false,
            },
            pathname,
          )
        : false;
      acc.push(
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          count={item.count}
          drawerOpen={drawerOpen}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname,
            drawerOpen,
          })}
        </NavItem>,
      );
    } else {
      acc.push(
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
          count={item.count}
          drawerOpen={drawerOpen}
        />,
      );
    }
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, drawerOpen, ...other } = props;
  const { user } = useAuth();

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "0.75rem",
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        />
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        drawerOpen,
        user,
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  drawerOpen: PropTypes.bool,
};

export default NavSection;
