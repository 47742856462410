/* eslint-disable no-unused-vars */
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import GoogleLogin from "react-google-login";
import { useGoogleLoginMutation } from "../../services/userApi";
import Logo from "../../components/Logo";
import apiUtils from "../../utils/api";
import toast from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import GoogleIcon from "../../icons/Google";

const Login = () => {
  const [googleLogin, { isLoading: isLoggingIn }] = useGoogleLoginMutation();
  const navigate = useNavigate();
  const { refreshLogin } = useAuth();

  const handleLogin = async (response) => {
    const googleData = response;
    googleData.profileObj = apiUtils.camelToSnakeCase(googleData.profileObj);
    const token = await googleLogin(apiUtils.camelToSnakeCase(googleData));
    if (token.error) {
      toast.error(token.error.status === 404 ? "You are not a registered user." : "Cannot connect to the server.");
    } else {
      toast.success("You have successfully logged in!");
      await refreshLogin(String(token.data.refresh));
      navigate("/");
    }
  };
  const handleLoginError = (response) => {
    console.log("login error:", response);
  };

  return (
    <>
      <Helmet>
        <title>Log In | Houser by Nutracap</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ py: 2, display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}
        >
          <Card sx={{ py: 4, mx: "auto", display: "flex" }}>
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 2,
                  px: 4,
                }}
              >
                <RouterLink to="/">
                  <img src="/houser-banner.png" alt="houser-banner" width="400" />
                </RouterLink>
              </Box>
            </CardContent>
            <Box sx={{ width: "1px", height: 480, background: "rgba(0, 0, 0, 0.15)", alignSelf: "center" }} />
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: 4,
                px: 8,
              }}
            >
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: 28, fontWeight: 700 }} color="primary">
                  Welcome to Houser
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 500, mt: -0.5, mb: 2 }} color="#131146">
                  a software by Nutracap
                </Typography>
                <Typography fontSize={16}>Login with your Google Account to continue</Typography>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  mb: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 320,
                  width: "100%",
                }}
              >
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Continue with Google"
                  onSuccess={handleLogin}
                  onFailure={handleLoginError}
                  cookiePolicy="single_host_origin"
                  // style={{ border: "1px solid rgba(0,0,0. 0.26)" }}
                  render={(renderProps) => (
                    <LoadingButton
                      startIcon={<GoogleIcon />}
                      sx={{ color: "#001C4E", width: "100%" }}
                      variant="outlined"
                      color="secondary"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      loadingPosition="start"
                      loading={isLoggingIn}
                    >
                      Continue with Google
                    </LoadingButton>
                  )}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
