import createSvgIcon from "@mui/material/utils/createSvgIcon";

const Kanban = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Icon"
      fillRule="evenodd"
      d="m21 6v12c0 1.7-1.3 3-3 3h-12c-1.7 0-3-1.3-3-3v-12c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3zm-15 13h5v-9h-6v8c0 0.6 0.4 1 1 1zm-1-13v2h14v-2c0-0.6-0.4-1-1-1h-12c-0.6 0-1 0.4-1 1zm14 12v-8h-6v9h5c0.6 0 1-0.4 1-1z"
    />
  </svg>,
  "Kanban",
);

export default Kanban;
