import WarningIcon from "./icons/ExclamationTriangle";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const TOASTER_THEME = {
  warning: {
    icon: <WarningIcon fontSize="medium" />,
    style: {
      background: "#FFAA00",
    },
    iconTheme: {
      primary: "#FFAA00",
      secondary: "#FFFFFF",
    },
  },
};

export const MESSAGES = {
  API_ERROR: "An error occured while saving changes.",
  API_SUCCESS: (model) => `${model} saved`,
  API_FETCH_ERROR: "An error occured while fetching data.",
};

export const ROLES = {
  0: "User",
  1: "Admin",
  2: "Sales",
  3: "Sample Lab",
  4: "Viewer",
  5: "Quality Manager",
  6: "Quality Specialist",
};
