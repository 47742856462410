import createSvgIcon from "@mui/material/utils/createSvgIcon";

const Medicine = createSvgIcon(
  <svg viewBox="0 0 24 24" width="24" height="24">
    <g id="Layer">
      <path
        id="Layer"
        fillRule="evenodd"
        d="m22 15.8l-5.8 5.8c-1.2 1.2-2.7 0.9-2.9 0.9-0.1 0-0.6 0.3-1.6 0.4h-7.1c-1.9 0-3.5-1.6-3.5-3.5v-10.1c0-1.6 1.1-3 2.5-3.4v-3.9c0-0.5 0.4-0.9 0.9-0.9h7.3c0.5 0 0.9 0.4 0.9 0.9v3.9c1.5 0.4 2.5 1.7 2.5 3.4v4.1l2.2-2.2c1.7-1.7 3.7-0.6 4.4 0 1.4 1.4 1.4 3.4 0.2 4.6zm-16.6-10.1h5.4v-2.8h-5.4zm-0.8 15.3h6.6c-0.8-1.2-0.7-2.9 0.4-4l1.8-1.8v-6c0-0.9-0.8-1.7-1.7-1.7h-7.1c-0.9 0-1.6 0.8-1.6 1.7v10.1c0 0.9 0.7 1.7 1.6 1.7zm12.6-3l-2-2-2.3 2.3c-0.9 1.1 0.2 2 0.2 2 0.5 0.5 1.3 0.5 1.8 0zm3.6-5.4c-1-0.9-1.8-0.4-2-0.1l-2.3 2.2 2 2 2.3-2.2c0.5-0.6 0.5-1.4 0-1.9z"
      />
    </g>
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
    <g id="Layer" />
  </svg>,
  "Medicine",
);

export default Medicine;
