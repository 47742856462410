import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
// import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  // const { isAuthenticated } = useAuth();
  const isAuthenticated = false;

  if (isAuthenticated) {
    return <Navigate to="/account" />;
  }

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
