import { CssBaseline, ThemeProvider } from "@mui/material";
import RTL from "./components/RTL";
import { createCustomTheme } from "./theme";
import { THEMES } from "./constants";
import routes from "./routes";
import { RouterProvider } from "react-router";
import useAuth from "./hooks/useAuth";
import SplashScreen from "./components/SplashScreen";
import AppToaster from "./components/AppToaster";
import { createBrowserRouter } from "react-router-dom";

const App = () => {
  const auth = useAuth();

  const theme = createCustomTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
  });
  // const content = useRoutes(routes);

  const router = createBrowserRouter(routes);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction="ltr">
        <CssBaseline />
        <AppToaster />
        {auth.isInitialized ? <RouterProvider router={router} /> : <SplashScreen />}
      </RTL>
      {/* {token != null && <Link token={token} />} */}
    </ThemeProvider>
  );
};

export default App;
